function getTimes() {
    var x = 5;
    var tt = 0; 
    var ap = ['am', 'pm'];
    var allTimes = [];

    for (var i=0;tt < 24*60; i++) {
        var hh = Math.floor(tt/60);
        var mm = (tt%60);
        var hr = 0;
        if(hh == 0 || hh == '12') {
            hr = 12;
        } else {
            hr = ("0" + (hh % 12)).slice(-2);
        }
        let hr_format = hh;
        // let check_min = ("0" + mm).slice(-2);
        // if(hh == 0 && check_min == '00') {
            // hr_format = 24;
        // }
        let label = hr + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)];
        let value = hr_format + ':' + ("0" + mm).slice(-2);
        allTimes.push({label: label, value: value });
        
        tt = tt + x;
    }
    return allTimes;
}

export default getTimes;